import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .logo-header {
              display: flex;
              gap: 8px;
              align-items: center;
  }
  .logo-header span {
              font-size: 40px;
  }

          
  .footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 71px;
  }

  @media only screen and (max-width: 768px) {
    .logo-header {
      margin-left: -80px;
    }
    .logo-header span {
      font-size: 35px;
    }
    
    .footer-links {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 32px;
      gap: 12px;
      padding-bottom: 18px;
    }

    
    
  }
`

export default GlobalStyle
